<template>
  <div v-if="collection" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-6">
    <p class="mt-4" v-if="collection.data && collection.data.length === 0">{{$t("public.infos.noInstallationFound")}}</p>
    <div v-else>
      <Pagination 
        :entity="entity"
        :storeKey="storeKey" 
        :status="status"
      />
      <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-4 border-t border-b border-gray-200">
        <slot></slot>
      </ul>
      <Pagination 
        :entity="entity"
        :storeKey="storeKey" 
        :status="status"
      />
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/layout/Pagination.vue'

export default {
  name: "AdminPagination",
  components: {
    Pagination
  },
  props: {
    entity: String,
    status: String,
    storeKey: String,
  },
  computed: {
    collection () {
      return this.$store.state[this.entity][this.storeKey]
    }
  },
  async created() { 
    this.$store.dispatch('setLoading', true)
    await this.$store.dispatch('getCollection', {entity: this.entity, key: this.storeKey, status: this.status, page: 1})
    this.$store.dispatch('setLoading', false)
  }
}
</script>